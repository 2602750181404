<template>
  <div id="app">
    <div class="d-flex flex-column justify-content-center align-items-center" style="height: 80vh">
      <div class="display-1 m-3">你好</div>
    </div>
    <div class="d-flex justify-content-center">
      <img src="beian.png" style="float:left;"/>
      <a target="_blank" href="https://beian.miit.gov.cn/" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">浙ICP备16043755号-1</p></a>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.display-1 {
  font-size: 5rem;
}
.d-flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column !important;
}
.justify-content-center {
  justify-content: center !important;
}
.align-items-center {
  align-items: center !important;
}
</style>
